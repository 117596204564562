import {useContext, useEffect, useState} from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Input } from "reactstrap";
import UserContext from "../../../context/userContext";
import AppFormLabel from "../forms/AppFormLabel";
import * as Yup from "yup";
import Message from "../../../utility/notifications/Message";
import PageTitle from "./PageTitle";
import { Formik } from "formik";
import AppTextInput from "../forms/AppTextInput";
import { changePassword } from "../../../services/profileService";
import AppButton from "./AppButton";
import OutOfOfficeForm from "./OutOfOfficeForm";
import React from "react";
import { isHostUser } from "../../../utility/Utils";
import {getCurrentTenantInformation} from "../../../services/sessionService";
import {TenantSessionData} from "../../../types";
import {GetTenantInformationOutput} from "../../../types/apiTypes";
import moment from "moment";

const validationSchemaPassword = Yup.object().shape({
  currentPassword: Yup.string().required().label("Current password"),
  newPassword: Yup.string().required().label("Password"),
  newPasswordConfirmation: Yup.string()
    .required()
    .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
    .label("Repeat password"),
});

const Profile = () => {
  const [isSavingUser, setIsSavingUser] = useState(false);
  const { currentUser } = useContext(UserContext);
  const interfaceVersion = process.env.REACT_APP_INTERFACE_VERSION
  const [sessionData, setSessionData] = useState<GetTenantInformationOutput | undefined>()

    async function getTenantSessionData() {
        const result = await getCurrentTenantInformation();
        const sessionResult = result.data.result as TenantSessionData;

        if (sessionResult) {
            setSessionData(sessionResult);
        }
    }

    useEffect(() => {
        getTenantSessionData()
    }, [])

  const handlePasswordChangeSubmit = async (pwReset, { resetForm }) => {
    setIsSavingUser(true);

    await changePassword(pwReset)
      .then((res) => {
        if (res.status === 200) {
          setIsSavingUser(false);
          Message(
            "Password updated",
            "Your password has been changed",
            "success"
          );

          resetForm({
            currentPassword: "",
            newPassword: "",
            newPasswordConfirmation: "",
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          setIsSavingUser(false);

          Message(
            err.response.data.error.message,
            err.response.data.error.details,
            "error"
          );
        }
      });
  };

  return (
    <>
      <Row className={'fst-italic text-light-grey'}>
        {interfaceVersion && <div className={'d-flex justify-content-end gap-1'}>
          <span>Interface version:</span>
          <span>{interfaceVersion}</span>
        </div>}
        {sessionData && <div className={'d-flex justify-content-end gap-1'}>
          <span>API version:</span>
          <span>{moment(sessionData?.apiReleaseDate).format(" DD-MM-yyyy")}</span>
        </div>}
    </Row>
      <PageTitle title="My profile" />

      <Row>
        <Col lg={6} sm={12}>
          <div className="mb-3">
            <AppFormLabel for="floatingInput" label="Email" />

            <Input disabled value={currentUser?.email} />
          </div>
        </Col>
        <Col lg={6} sm={12}>
          <div className="mb-3">
            <AppFormLabel for="floatingInput" label="Name" />

            <Input disabled value={currentUser?.fullName} />
          </div>
        </Col>
      </Row>

      <Row>
        <Col sm={12}>
          <div className="mb-3">
            <AppFormLabel for="floatingInput" label="Roles" />

            <Input disabled value={currentUser?.roles} />
          </div>
        </Col>
      </Row>

      <hr></hr>

      <PageTitle title="Update password" customStyles="h4" />

      <Formik
        enableReinitialize={true}
        onSubmit={handlePasswordChangeSubmit}
        validationSchema={validationSchemaPassword}
        initialValues={{
          currentPassword: "",
          newPassword: "",
          newPasswordConfirmation: "",
        }}
      >
        {({ handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <>
              <Row>
                <Col lg={6} sm={12}>
                  <div className="mb-3">
                    <AppTextInput
                      type="password"
                      name="currentPassword"
                      label="Your current password"
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col lg={6} sm={12}>
                  <div className="mb-3">
                    <AppTextInput
                      type="password"
                      name="newPassword"
                      label="Password"
                    />
                  </div>
                </Col>
                <Col lg={6} sm={12}>
                  <div className="mb-3">
                    <AppTextInput
                      type="password"
                      name="newPasswordConfirmation"
                      label="Repeat password"
                    />
                  </div>
                </Col>
              </Row>
            </>

            <Row className="text-end">
              <Col>
                <AppButton
                  title="Update password"
                  disabled={isSavingUser}
                  loading={isSavingUser}
                  variant="save"
                  buttonSize="lg"
                  type="submit"
                />
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
      <>
      
      {!isHostUser() && (
        <>
          <h4 className={"my-5 text-center"}>Replacement</h4>
          <Row>
            <OutOfOfficeForm />
          </Row>
      </>
      )}
      </>
    </>
  );
};

export default Profile;
