import { Button, Spinner } from "react-bootstrap";
import {
  faSave,
  faCloudUploadAlt,
  faBan,
  faShareSquare,
  faChevronLeft,
  faDownload,
  faTableList,
  faListSquares,
  faSearch, faUnlock,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const buttonIcons = {
  cancel: <FontAwesomeIcon className="text-center me-2" icon={faBan as any} />,
  save: <FontAwesomeIcon className="text-center me-2" icon={faSave as any} />,
  submit: (
    <FontAwesomeIcon
      className="text-center me-2"
      icon={faCloudUploadAlt as any}
    />
  ),
  download: (
    <FontAwesomeIcon className="text-center me-2" icon={faDownload as any} />
  ),
  search: (
    <FontAwesomeIcon className="text-center me-2" icon={faSearch as any} />
  ),

  impersonate: (
    <FontAwesomeIcon className="text-center me-2" icon={faShareSquare as any} />
  ),
  table: (
    <FontAwesomeIcon className="text-center me-2" icon={faTableList as any} />
  ),
  square: (
    <FontAwesomeIcon className="text-center me-2" icon={faListSquares as any} />
  ),
  back: (
    <FontAwesomeIcon className="text-center me-2" icon={faChevronLeft as any} />
  ),
  unlock: (
    <FontAwesomeIcon className="text-center me-2" icon={faUnlock as any} />
  ),
};

const variantColors = {
  cancel: "secondary",
  save: "primary",
};

interface ButtonProps {
  className?: string;
  title: string;
  variant?:
    | "cancel"
    | "refresh"
    | "save"
    | "back"
    | "export"
    | "clear"
    | "submit"
    | "table"
    | "square"
    | "search"
    | "download"
    | "impersonate"
    | 'unlock'
    | undefined;
  buttonSize?: "lg" | "sm";
  loading?: boolean;
  disabled?: boolean;
  // All other props
  [x: string]: any;
}

const AppButton: React.FC<ButtonProps> = ({
  className,
  title,
  variant,
  buttonSize = "lg",
  loading = false,
  disabled = false,
  ...otherProps
}) => {
  return (
    <>
      <Button
        disabled={disabled}
        className={className}
        size={buttonSize as any}
        variant={variantColors[variant as keyof typeof variantColors]}
        {...otherProps}
      >
        {loading && (
          <Spinner
            as="span"
            animation="grow"
            size="sm"
            role="status"
            aria-hidden="true"
            className="me-2"
          />
        )}
        {!loading &&
          variant &&
          buttonIcons[variant as keyof typeof buttonIcons]}
        {title}
      </Button>
    </>
  );
};

export default AppButton;
