import { Form, Formik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Link, useNavigate, useParams } from "react-router-dom";
import {unlockUser} from "../../services/userService";
import {
  Col,
  Input,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import * as Yup from "yup";
import UserContext from "../../context/userContext";
import routeNames from "../../router/routeNames";
import { impersonate } from "../../services/accountService";
import {
  createTenant,
  getTenant,
  getUsersForTenant,
  updateTenant,
} from "../../services/tenantService";
import useJwt from "../../services/useJwt";
import {
  CreateOrUpdateTenantInput,
  ImpersonateInput,
  TenantUser,
} from "../../types";
import Message from "../../utility/notifications/Message";
import Notify from "../../utility/notifications/Notify";
import AppButton from "../components/Common/AppButton";
import PageTitle from "../components/Common/PageTitle";
import AppCheckboxInput from "../components/forms/AppCheckboxInput";
import AppTextInput from "../components/forms/AppTextInput";
import { TenantSignOffFlowDropdown } from "../../utility/constants";
import AppSelectInput from "../components/forms/AppSelectInput";
import {TenantSignOffFlow, TenantUsersDto} from "../../types/apiTypes";
import { handleLoggedInRoute } from "../../router/routeHelper";

const validationSchema = Yup.object().shape({
  id: Yup.number(),
  tenancyName: Yup.string().required().label("Organisation name"),
  name: Yup.string().required().label("Friendly name"),
  adminEmailAddress: Yup.string()
    .when("id", {
      is: (id) => !id,
      then: Yup.string().required(),
    })
    .label("Admin email"),
  password: Yup.string()
    .when("id", {
      is: (id) => !id,
      then: Yup.string().required(),
    })
    .label("Password"),
  passwordConfirmation: Yup.string()
    .when("id", {
      is: (id) => !id,
      then: Yup.string()
        .required()
        .oneOf([Yup.ref("password"), null], "Passwords must match"),
    })
    .label("Repeat password"),
});

const CreateOrEditTenant = () => {
  const [isSavingTenant, setIsSavingTenant] = useState(false);
  const [tenant, setTenant] = useState<CreateOrUpdateTenantInput>();
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState("1");
  const [tenantUsers, setTenantUsers] = useState<TenantUsersDto[]>();
  const [searchTerm, setSearchTerm] = useState<string>("");

  const userContext = useContext(UserContext);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const initialValues: CreateOrUpdateTenantInput = {
    tenancyName: tenant ? tenant.tenancyName : "",
    isActive: tenant ? tenant.isActive : true,
    name: tenant ? tenant.name : "",
    adminEmailAddress: tenant ? tenant.adminEmailAddress : "",
    id: tenant?.id,
    isStayplease: tenant ? tenant.isStayplease : false,
    hasCustomAuthenticationFlow: tenant
      ? tenant.hasCustomAuthenticationFlow
      : false,
    tenantSignOffFlow: tenant
      ? tenant.tenantSignOffFlow
      : TenantSignOffFlow.Value1,
  };

  const { id } = useParams();

  const navigate = useNavigate();

  const fetchTenant = async (id) => {
    const result = await getTenant(id);

    if (result) {
      setTenant(result.data.result);
    }
  };

  const fetchTenantUser = async (id) => {
    const result = await getUsersForTenant(id, searchTerm);

    if (result) {
      setTenantUsers(result.data.result);
    }
  };

  const ImpersonateUser = async (userId, tenantId) => {
    const input: ImpersonateInput = {
      userId: userId,
      tenantId: tenantId,
    };

    try {
      const result = await impersonate(input);
      if (result) {
        handleImpersonation(result.data.result.impersonationToken);
      }
    } catch {
      Message(
        "The impersonation attempt failed",
        "The impersonation attempt failed, likely because the login is already impersonated. Please log out first.",
        "error"
      );
    }
  };

  const handleImpersonation = async (impersonationToken) => {
    useJwt.impersonatedAuthenticate(impersonationToken).then((res) => {
      if (res.status === 200) {
        const data = {
          accessToken: res.data.result.accessToken,
          ...res.data.result.user,
          isHost: false,
          refreshToken: res.data.result.refreshToken,
          isImpersonated: true,
        };
        userContext.onLoggedIn(data);

        navigate(handleLoggedInRoute());
      }
    });
  };

  async function handleUnlockUser(user) {
    Message(
        "Are you sure?",
        `Do you want to unlock ${user.name}?`,
        "warning"
    ).then(async (result) => {
      if (result.isConfirmed) {
        await unlockUser(user.userId)
            .then(async (res) => {
              if (res.status === 200) {
                Notify.success(
                    `${user.name}'s account is unlocked!`
                );

                fetchTenantUser(id)
              }
            })
            .catch((err) => {
              if (err.response) {
                Message(
                    err.response.data.error.message,
                    err.response.data.error.details,
                    "error"
                );
              }
            });
      }
    });
  }

  useEffect(() => {
    if (id) {
      fetchTenant(id);
      fetchTenantUser(id);
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, [id, searchTerm]);

  const handleSubmit = async (tenant: CreateOrUpdateTenantInput) => {
    setIsSavingTenant(true);

    if (tenant.id) {
      await updateTenant(tenant)
        .then((res) => {
          if (res.status === 200) {
            setIsSavingTenant(false);
            Notify.success(`Tenant ${tenant.name} has been updated`);
            navigate(routeNames.tenants);
          }
        })
        .catch((err) => {
          if (err.response) {
            setIsSavingTenant(false);

            Message(
              err.response.data.error.message,
              err.response.data.error.details,
              "error"
            );
          }
        });
    } else {
      await createTenant(tenant)
        .then((res) => {
          if (res.status === 200) {
            setIsSavingTenant(false);
            Notify.success(`Tenant ${tenant.name} has been created`);
            navigate(routeNames.tenants);
          }
        })
        .catch((err) => {
          if (err.response) {
            setIsSavingTenant(false);

            Message(
              err.response.data.error.message,
              err.response.data.error.details,
              "error"
            );
          }
        });
    }
  };

  async function handleChange(event) {
    setSearchTerm(event.target.value);
  }

  return (
    <>
      <PageTitle title={id ? "Update tenant" : "Configure tenant"} />

      <Nav tabs>
        <NavItem>
          <NavLink
            className={activeTab === "1" ? "active" : ""}
            onClick={() => {
              toggle("1");
            }}
          >
            Tenant config
          </NavLink>
        </NavItem>

        {tenant?.id && (
          <NavItem>
            <NavLink
              disabled={!tenant?.isActive}
              className={activeTab === "2" ? "active" : ""}
              onClick={() => {
                toggle("2");
              }}
            >
              Impersonation
            </NavLink>
          </NavItem>
        )}
      </Nav>

      <TabContent activeTab={activeTab}>
        {!loading && (
          <TabPane tabId="1">
            <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              onSubmit={handleSubmit}
              validationSchema={validationSchema}
            >
              {({ handleSubmit, values }) => (
                <Form onSubmit={handleSubmit}>
                  <Row className="mt-2">
                    <h4>Organisation settings</h4>
                    <Col lg={6} sm={12}>
                      <div className="mb-3">
                        <AppTextInput
                          type="text"
                          placeholder="Organisation"
                          name="tenancyName"
                          label="Organisation"
                        />
                      </div>
                    </Col>
                    <Col lg={6} sm={12}>
                      <div className="mb-3">
                        <AppTextInput
                          type="text"
                          placeholder="Name"
                          name="name"
                          label="Friendly name"
                        />
                      </div>
                    </Col>
                  </Row>

                  <hr></hr>

                  <PageTitle
                    title="Tenant configuration settings"
                    customStyles="h4"
                    hideBackButton
                  />

                  <Row>
                    <Col lg={6}>
                      <div className="mb-3">
                        <AppCheckboxInput
                          label="Enable this checkbox if this tenant should retrieve its data from the Stayplease application"
                          name="isStayplease"
                        />
                      </div>
                    </Col>
                    
                    <Col lg={6}>
                      <div className="mb-3">
                        <AppCheckboxInput
                          label="Enable this checkbox if this tenant should be active"
                          name="isActive"
                        />
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={6}>
                      <div className="mb-3">
                        <AppCheckboxInput
                          label="Enable this checkbox if this tenant uses a custom authentication flow (such as SSO)"
                          name="hasCustomAuthenticationFlow"
                        />
                      </div>
                    </Col>
                  </Row>

                  <Row className="mb-2 mt-3">
                    <Col lg={6}>
                      <AppSelectInput
                        disabled={!!values.id}
                        name="tenantSignOffFlow"
                        options={TenantSignOffFlowDropdown}
                        label={"Select the signoff flow"}
                        callback={undefined}
                      />
                    </Col>
                  </Row>

                  {!values.id && (
                    <>
                      <Row className="pt-5">
                        <h4>Admin user settings</h4>
                        <Col>
                          <div className="mb-3">
                            <AppTextInput
                              type="email"
                              placeholder="Administrator email"
                              name="adminEmailAddress"
                              label="Administrator email"
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={6} sm={12}>
                          <div className="mb-3">
                            <AppTextInput
                              type="password"
                              placeholder="Password"
                              name="password"
                              label="Password"
                            />
                          </div>
                        </Col>
                        <Col lg={6} sm={12}>
                          <div className="mb-3">
                            <AppTextInput
                              type="password"
                              placeholder="Repeat password"
                              name="passwordConfirmation"
                              label="Repeat password"
                            />
                          </div>
                        </Col>
                      </Row>
                    </>
                  )}

                  <Row className="text-end">
                    <Col>
                      <Link to={routeNames.tenants}>
                        <AppButton
                          title="Cancel"
                          variant="cancel"
                          className="me-3"
                        />
                      </Link>

                      <AppButton
                        title={values.id ? "Update tenant" : "Create tenant"}
                        disabled={isSavingTenant}
                        loading={isSavingTenant}
                        variant="save"
                        type="submit"
                      />
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </TabPane>
        )}

        {tenantUsers && (
          <TabPane tabId="2">
            <Row className="mt-2">
              <h4>Users in tenant</h4>
              <p>
                User Impersonation allows Administrators to access and operate
                as if they were logged in as that User. If you would like to
                impersonate a user press the button.
              </p>

              <Row className="pb-2">
                <Col xs={12} className="d-flex justify-content-end mb-2">
                  <Input
                    placeholder="Search for name..."
                    className="me-2"
                    onChange={(event) => handleChange(event)}
                  />
                </Col>
              </Row>

              <DataTable
                pagination
                responsive
                columns={[
                  {
                    name: "name",
                    selector: (row) => row.name,
                    sortable: false,
                  },
                  {
                    name: "Actions",
                    cell: (row) => (
                      <div className={'d-flex gap-2'}>
                        <AppButton
                          onClick={() => ImpersonateUser(row.userId, id)}
                          size="sm"
                          color="primary"
                          title="Impersonate"
                          variant="impersonate"
                        />
                        {
                          row?.isLocked && <AppButton
                            onClick={() => handleUnlockUser(row)}
                            size="sm"
                            color="primary"
                            title="Unlock account"
                            variant="unlock"
                          />
                        }
                      </div>
                    ),
                  },
                ]}
                data={tenantUsers}
              />
            </Row>
          </TabPane>
        )}
      </TabContent>
    </>
  );
};

export default CreateOrEditTenant;
