import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import { Button, Container, Form } from "reactstrap";
import * as Yup from "yup";
import {
  createOrUpdateArticleItem,
  deleteProjectArticleConfiguration,
  getApproversForProject,
  getProjectArticleItems,
} from "../../services/projectService";
import { itemCodeApplicableDays } from "../../utility/constants";
import Message from "../../utility/notifications/Message";
import Notify from "../../utility/notifications/Notify";
import AppCheckboxInput from "../components/forms/AppCheckboxInput";
import AppFormTagBox from "../components/forms/AppFormTagBox";
import AppSelectInput from "../components/forms/AppSelectInput";
import AppTextInput from "../components/forms/AppTextInput";
import CopyConfigurationModal from "./CopyConfigurationModal";
import { useNavigate } from "react-router-dom";

import { SubmitButton } from "../components/buttons/SubmitButton";
import Page from "../components/Page";

const validationSchema = Yup.object().shape({
  items: Yup.array()
    .of(
      Yup.object().shape({
        itemName: Yup.string().required().nullable().label("Itemname"),
        sorting: Yup.number().min(0).nullable().required("Sort order"),
        itemCodes: Yup.array().min(1).required().label("Itemcodes"),
        applicableDays: Yup.array().of(Yup.number()).nullable(),
      })
    )
    .test(
      "unique-applicableDays",
      "Applicable days overlap or requirements not met",
      function (items) {
        const itemGroup = items.reduce((acc, item, index) => {
          if (!acc[item.itemName]) {
            acc[item.itemName] = [];
          }
          acc[item.itemName].push({ data: item, index });
          return acc;
        }, {});

        const itemNames = Object.keys(itemGroup);
        for (let name of itemNames) {
          const group = itemGroup[name];
          if (group.length > 1) {
            let daySet = new Set();
            for (let i = 0; i < group.length; i++) {
              const item = group[i].data;
              const currentIndex = group[i].index;
              if (!item.applicableDays || item.applicableDays.length === 0) {
                throw this.createError({
                  path: `items[${currentIndex}].applicableDays`,
                  message:
                    "Applicable days cannot be empty when multiple items share the same name.",
                });
              }
              const currentDays = new Set(item.applicableDays);
              for (let day of currentDays) {
                if (daySet.has(day)) {
                  throw this.createError({
                    path: `items[${currentIndex}].applicableDays`,
                    message:
                      "Applicable days overlap with another item having the same name.",
                  });
                }
              }
              // Merge the days to the set to compare with the next item
              item.applicableDays.forEach((day) => daySet.add(day));
            }
          }
        }
        return true; // If all checks pass, return true
      }
    )
    .test(
      "unique-sortOrder",
      "Sort order must be unique across all items",
      function (items) {
        const sortOrderSet = new Set();
        for (let i = 0; i < items.length; i++) {
          const sortOrder = items[i].sorting;
          if (sortOrderSet.has(sortOrder)) {
            return this.createError({
              path: `items[${i}].sorting`,
              message: "Sort order must be unique.",
            });
          }
          sortOrderSet.add(sortOrder);
        }
        return true; // If all checks pass, return true
      }
    ),
});

const ItemCodeTab = ({ projectId }) => {
  const navigate = useNavigate();

  const [itemData, setItemData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [projectApprovers, setProjectApprovers] = useState();
  const [showCopyConfigurationModal, setShowCopyConfigurationModal] =
    useState(false);

  async function fetchItems() {
    setLoading(true);
    const response = await getProjectArticleItems(projectId);

    const mappedObject = response.data.result.items.map((configItem) => {
      configItem.itemCodes = configItem.itemCodes.map((code) => {
        return { label: code.itemCode, value: code.id };
      });

      configItem.linkedApprovers = configItem.linkedApprovers.sort(
        (a, b) => a.roleId - b.roleId
      );

      return configItem;
    });

    setItemData(mappedObject);

    setLoading(false);
  }

  async function fetchApproversForProject() {
    setLoading(true);
    const response = await getApproversForProject(projectId);

    setProjectApprovers(
      response.data.result.sort((a, b) => a.roleId - b.roleId)
    );

    setLoading(false);
  }

  useEffect(() => {
    fetchItems();
    fetchApproversForProject();
  }, [projectId]);

  const deleteRemovedItems = (configurationItems) => {
    itemData.map(async (item) => {
      if (configurationItems.filter((e) => e.id === item.id).length === 0) {
        await deleteProjectArticleConfiguration(item.id);
      }
    });
  };

  const handleSubmit = async (configurationItems) => {
    setSaving(true);

    const mappedObject = configurationItems.items.map((configItem) => {
      configItem.itemCodes = configItem.itemCodes.map((code) => {
        return { itemCode: code.label, id: code.value };
      });

      configItem.approverIds = configItem.linkedApprovers
        .filter((linkedApp) => linkedApp.isChecked)
        .map((linkedApprover) => {
          return linkedApprover.roleId;
        });

      delete configItem.linkedApprovers;

      return configItem;
    });

    deleteRemovedItems(configurationItems.items.filter((e) => e.id));

    await createOrUpdateArticleItem({
      projectId: projectId,
      items: mappedObject,
    })
      .then((res) => {
        Notify.success(`Configuration has been saved`);
        setSaving(false);

        navigate(-1);
      })
      .catch((err) => {
        setSaving(false);
        Message(
          err.response.data.error.message,
          err.response.data.error.details,
          "error"
        );
      });
  };

  const handleDelete = (index, values, setValues) => {
    const filteredItemList = values.items.filter((_, i) => i !== index);
    setValues({
      items: filteredItemList,
    });
  };

  if (!projectId) {
    return (
      <div className="mt-2">
        <h5>You can configure articles after creating the project</h5>
      </div>
    );
  }

  return (
    <Page loading={loading} loadingMessage="Retrieving article configuration">
      <div className="mt-2">
        {!loading && itemData.length === 0 && (
          <h5>
            No article configurations found yet. Click the 'add' button below to
            add a configuration
          </h5>
        )}

        <Formik
          enableReinitialize={true}
          initialValues={{ items: itemData }}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({ handleSubmit, values, setValues }) => (
            <Form onSubmit={handleSubmit} className="mt-2">
              <Container fluid className="scrollable-container">
                <div className="header-row">
                  <div className="column-actions">Actions</div>
                  <div className="column-item-name">Item name</div>
                  <div className="column-item-code">Item code</div>
                  <div className="column-sort-order">Sort order</div>
                  <div className="column-app-days">Applicable days</div>
                  <div className="column-dont-send">Do not send to AFAS</div>
                  <div className="column-is-active">Is Active</div>
                  {projectApprovers?.map((approver) => (
                    <div className="column-approver" key={approver.roleId}>
                      {approver.roleName}
                    </div>
                  ))}
                </div>

                {values.items?.map((item, index) => (
                  <div className="content-row mb-2" key={index}>
                    <div className="column-actions">
                      <Button
                        onClick={() => handleDelete(index, values, setValues)}
                        size="sm"
                        color="danger"
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </Button>
                    </div>
                    <div className="column-item-name">
                      <AppTextInput
                        name={`items[${index}].itemName`}
                        required
                      />
                    </div>
                    <div className="column-item-code">
                      <AppFormTagBox
                        key={`itemcode-key[${index}]`}
                        name={`items[${index}].itemCodes`}
                        options={item.itemCodes}
                      />
                    </div>
                    <div className="column-sort-order">
                      <AppTextInput
                        type="number"
                        name={`items[${index}].sorting`}
                        required
                      />
                    </div>
                    <div className="column-app-days">
                      <AppSelectInput
                        isMultiple={true}
                        name={`items[${index}].applicableDays`}
                        options={itemCodeApplicableDays}
                      />
                    </div>
                    <div className="column-dont-send">
                      <AppCheckboxInput
                        name={`items[${index}].doNotSendToAFAS`}
                      />
                    </div>
                    <div className="column-is-active">
                      <AppCheckboxInput
                        name={`items[${index}].isActive`}
                        required
                        label={""}
      
                      />
                    </div>
                    {item.linkedApprovers?.map((approver, approverIndex) => (
                      <div className="column-approver" key={approverIndex}>
                        <AppCheckboxInput
                          name={`items[${index}].linkedApprovers[${approverIndex}].isChecked`}
                        />
                      </div>
                    ))}
                  </div>
                ))}
              </Container>

              <div className="mt-5">
                <Button
                  color="secondary"
                  size="lg"
                  type="button"
                  disabled={saving}
                  onClick={() => {
                    const newItem = {
                      itemName: "",
                      itemCode: 0,
                      linkedApprovers: projectApprovers.map((approver) => {
                        return { isChecked: false, roleId: approver.roleId };
                      }),
                    };

                    setValues({
                      items: [...values.items, newItem],
                    });
                  }}
                >
                  Add configuration
                </Button>
              </div>

              <hr></hr>
              <div className="d-flex justify-content-between">
                <Button
                  color="secondary"
                  size="lg"
                  type="button"
                  className="align-self-start"
                  disabled={saving}
                  onClick={() => {
                    setShowCopyConfigurationModal(true);
                  }}
                >
                  Copy configuration
                </Button>

                <SubmitButton disabled={saving} title="Save configuration" />
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <CopyConfigurationModal
        projectId={projectId}
        setShowModal={setShowCopyConfigurationModal}
        showModal={showCopyConfigurationModal}
        type="ArticleConfig"
        onSavingDone={fetchItems}
      />
    </Page>
  );
};

export default ItemCodeTab;
