import React, { useEffect, useState } from "react";

import {
  createOrUpdateAdditionalItem,
  deleteAdditionalItem,
  getAdditionalItems,
} from "../../services/additionalItemService";

import * as Yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import Notify from "../../utility/notifications/Notify";
import Message from "../../utility/notifications/Message";
import {
  CreateOrUpdateAdditionalItem,
  CreateOrUpdateAdditionalItemInput,
  GetAdditionalItemsData,
} from "../../types";
import { Col, Row, Button } from "reactstrap";
import { Form, Formik } from "formik";
import AppTextInput from "../components/forms/AppTextInput";
import AppCheckboxInput from "../components/forms/AppCheckboxInput";
import CopyConfigurationModal from "./CopyConfigurationModal";
import AppDateInput from "../components/forms/AppDateInput";
import { SubmitButton } from "../components/buttons/SubmitButton";
import Page from "../components/Page";

interface AdditionalHoursTabProps {
  projectId: number;
}

const AdditionalHoursTab = ({ projectId }: AdditionalHoursTabProps) => {
  const [itemData, setItemData] = useState<GetAdditionalItemsData["result"]>();
  const [loading, setLoading] = useState(false);
  const [showAll, setShowAll] = useState(false);

  const [saving, setSaving] = useState(false);
  const [showCopyConfigurationModal, setShowCopyConfigurationModal] =
    useState(false);

  async function fetchItems() {
    setLoading(true);
    const response = await getAdditionalItems(projectId, showAll);
    setItemData(response.data.result);
    setLoading(false);
  }

  useEffect(() => {
    fetchItems();
  }, [projectId, showAll]);

  const handleSubmit = async (items: CreateOrUpdateAdditionalItemInput) => {
    setSaving(true);

    deleteRemovedItems(items?.items?.filter((e) => e.id));

    await createOrUpdateAdditionalItem(items)
      .then((res) => {
        Notify.success(`Configuration has been saved`);
        fetchItems();
        setSaving(false);
      })
      .catch((err) => {
        setSaving(false);
        Message(
          err.response.data.error.message,
          err.response.data.error.details,
          "error"
        );
      });
  };

  const deleteRemovedItems = (configurationItems) => {
    itemData?.map(async (item) => {
      if (configurationItems.filter((e) => e.id === item.id).length === 0) {
        await deleteAdditionalItem(item.id);
      }
    });
  };

  const handleDelete = (index, values, setValues) => {
    const filteredItemList = values.items.filter((_, i) => i !== index);
    setValues({
      ...values,
      items: filteredItemList,
    });
  };

  const initialValues: CreateOrUpdateAdditionalItemInput = {
    projectId: projectId,
    items: itemData ? itemData : [],
  };

  const validationSchema = Yup.object().shape({
    projectId: Yup.number().required().label("ProjectId"),
    items: Yup.array().of(
      Yup.object().shape({
        id: Yup.number().nullable(),
        startDate: Yup.date().nullable().label("Start date"),
        categoryName: Yup.string().required().label("Categoryname"),
        itemName: Yup.string().required().label("Itemname"),
        itemCode: Yup.string().required().label("itemCode"),
        sorting: Yup.string().required().label("Sorting"),
        isNumberOfRooms: Yup.boolean().nullable(),
        numberOfMinutes: Yup.number()
          .nullable()
          .label("Number of minutes")
          .when("isNumberOfRooms", {
            is: (isNumberOfRooms) => !isNumberOfRooms,
            then: Yup.number().required().label("Number of minutes"),
          })
          .label("Stayplease database name"),
      })
    ),
  });

  return (
    <Page
      loading={loading}
      loadingMessage="Retrieving additional request configuration"
    >
      <div className="mt-2">
        {!loading && itemData?.length === 0 && (
          <h5>
            No additional request configuration found yet. Click the 'add'
            button below to add a configuration
          </h5>
        )}
        <>
          <Row>
            <Col sm={1}>Actions</Col>
            <Col sm={2}>Category</Col>
            <Col sm={1}>Is Nr. Of rooms</Col>
            <Col sm={1}>Number of minutes</Col>
            <Col sm={2}>Start date</Col>
            <Col sm={2}>Item name</Col>
            <Col sm={1}>Code</Col>
            <Col sm={1}>Sort order</Col>
            <Col sm={1}>Is active</Col>
          </Row>
        </>

        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({ handleSubmit, values, setValues, setFieldValue }) => (
            <Form onSubmit={handleSubmit} className="mt-2">
              {values.items?.map((item, i) => {
                return (
                  <Row className="mb-4" key={i}>
                    <Col sm={1}>
                      <Button
                        onClick={() => handleDelete(i, values, setValues)}
                        size="sm"
                        color="danger"
                      >
                        <FontAwesomeIcon icon={faTrash as any} size="xs" />
                      </Button>
                    </Col>
                    <Col sm={2}>
                      <AppTextInput
                        type="text"
                        name={`items[${i}].categoryName`}
                        required
                      />
                    </Col>
                    <Col sm={1}>
                      <AppCheckboxInput
                        name={`items[${i}].isNumberOfRooms`}
                        required
                        label={""}
                        callback={(e) => {
                          if (e === true) {
                            setFieldValue(
                              `items[${i}].numberOfMinutes`,
                              undefined
                            );
                          }
                        }}
                      />
                    </Col>

                    <Col sm={1}>
                      {!item.isNumberOfRooms && (
                        <AppTextInput
                          type="number"
                          name={`items[${i}].numberOfMinutes`}
                          required
                        />
                      )}
                    </Col>

                    <Col sm={2}>
                      <AppDateInput name={`items[${i}].startDate`} />
                    </Col>

                    <Col sm={2}>
                      <AppTextInput
                        type="text"
                        name={`items[${i}].itemName`}
                        required
                      />
                    </Col>
                    <Col sm={1}>
                      <AppTextInput
                        type="number"
                        name={`items[${i}].itemCode`}
                        required
                      />
                    </Col>

                    <Col sm={1}>
                      <AppTextInput
                        type="number"
                        name={`items[${i}].sorting`}
                        required
                      />
                    </Col>
                    <Col sm={1}>
                      <AppCheckboxInput
                        name={`items[${i}].isActive`}
                        required
                        label={""}
          
                      />
                    </Col>
                  </Row>
                );
              })}
              <div className="d-flex justify-content-between">
                <Button
                  color="secondary"
                  size="lg"
                  type="button"
                  disabled={saving}
                  onClick={() => {
                    const newItem = {} as CreateOrUpdateAdditionalItem;

                    setValues({
                      ...values,
                      items: [...(values.items || []), newItem],
                    });
                  }}
                >
                  Add configuration
                </Button>

                {showAll ? (
                  <Button
                    color="secondary"
                    size="lg"
                    type="button"
                    disabled={saving}
                    onClick={() => setShowAll(!showAll)}
                  >
                    Show items with highest start date
                  </Button>
                ) : (
                  <Button
                    color="secondary"
                    size="lg"
                    type="button"
                    disabled={saving}
                    onClick={() => setShowAll(!showAll)}
                  >
                    Show all items
                  </Button>
                )}
              </div>

              <hr></hr>
              <div className="d-flex justify-content-between">
                <Button
                  color="secondary"
                  size="lg"
                  type="button"
                  className="align-self-start"
                  disabled={saving}
                  onClick={() => {
                    setShowCopyConfigurationModal(true);
                  }}
                >
                  Copy configuration
                </Button>

                <SubmitButton saving={saving} title="Save configuration" />
              </div>
            </Form>
          )}
        </Formik>
      </div>

      <CopyConfigurationModal
        projectId={projectId}
        setShowModal={setShowCopyConfigurationModal}
        showModal={showCopyConfigurationModal}
        type="AdditionalHoursConfig"
        onSavingDone={fetchItems}
      />
    </Page>
  );
};

export default AdditionalHoursTab;
