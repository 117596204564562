import React, { useEffect, useState } from "react";
import {useNavigate} from "react-router-dom";
import { getMyProjects } from "../../services/projectService";
import routeNames from "../../router/routeNames";
import ProjectSelectionPage from "../components/pages/projectSelectionPage";
import { ProjectListItem } from "../../types";

const DataApprovalOverview = () => {
  const navigate = useNavigate();
  const [projects, setProjects] = useState<ProjectListItem[]>();
  const [searchTerm, setSearchTerm] = useState<string>("");

  useEffect(() => {
    async function fetchUserProjects() {
      const projects = await getMyProjects(searchTerm);
      if (
        projects.data.result &&
        projects.data.result.length === 1 &&
        searchTerm == ""
      )
        navigate(`${routeNames.data_approval}/${projects.data.result[0].id}`);

      setProjects(projects.data.result);
    }

    fetchUserProjects();
  }, [navigate, searchTerm]);

  return (
    <ProjectSelectionPage
      projects={projects}
      buttonText="Open the daily sheet"
      onHandleSearch={(val) => {
        setSearchTerm(val);
      }}
      pageTitle="Data approval - project overview"
      onProjectSelection={(id) => navigate(`${routeNames.data_approval}/${id}`)}
    />
  );
};

export default DataApprovalOverview;
