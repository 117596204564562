import React, { useState } from "react";
import { Col, Row } from "reactstrap";

import approvedLogo from "../../assets/media/circle-check-solid.png";
import declinedLogo from "../../assets/media/circle-xmark-solid.png";
import outstandingLogo from "../../assets/media/spinner.png";

import { faRedo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { saveAs } from "file-saver";
import moment from "moment";
import { useEffect } from "react";
import Select from "react-select";
import downloadLogo from "../../assets/media/download.png";
import { getProjectAfasExport } from "../../services/hostDashboardService";
import { getMyProjects } from "../../services/projectService";
import { getStatistics } from "../../services/tenantDashboardService";
import { Comment, GetTenantDashboardData } from "../../types";
import { calculateNextPrevWeek } from "../../utility/DateFilters";
import useWeekPart from "../../utility/hooks/useWeekPart";
import AppButton from "../components/Common/AppButton";
import AppModal from "../components/Common/AppModal";
import DateToggler from "../components/Common/DateToggler";
import PageTitle from "../components/Common/PageTitle";
import AppDashboardAccordionItem from "../components/dashboards/AppDashboardAccordionItem";
import CustomAccordion from "../components/dashboards/CustomAccordion";
import MessageTimeLineItem from "../components/timeline/MessageTimeLineItem";
import CustomFilterExportModal from "./CustomFilterExportModal";
import ExportHFCOverviewDialog from "./dialogs/ExportHFCOverviewDialog";

const AreaManagerDashboard = () => {
  const [projectDashboardData, setProjectDashboardData] =
    useState<GetTenantDashboardData>();
  const [week, setWeek] = useState<number>();
  const [weekDescription, setWeekDescription] = useState<string>();
  const [year, setYear] = useState<number>();
  const [index, setIndex] = useState("");
  const [hasSecondWeekPart, setHasSecondWeekPart] = useState<boolean>();
  const [weekPart, setWeekPart] = useState<number>();
  const [loading, setLoading] = useState(false);
  const [projects, setProjects] = useState([]);
  const [projectFilter, setProjectFilter] = useState([]);
  const [showExportModal, setShowExportModal] = useState(false);
  const [showCustomRangeExportModal, setShowCustomRangeExportModal] =
    useState(false);

  const [accordionData, setAccordionData] = useState<any>();
  const [showCommentHistoryModal, setShowCommentHistoryModal] = useState(false);
  const [downloadingFile, setDownloadingFile] = useState(false);
  const [dateInfo, calculateWeekPart] = useWeekPart();

  const [comments, setComments] = useState<Comment[]>();

  useEffect(() => {
    fetchData(moment().year(), moment().isoWeek(), 1, projectFilter);
    getProjectDataForFilter();
  }, []);

  async function fetchData(
    year: number,
    week: number,
    weekPart: number,
    projectIds: number[]
  ) {
    setLoading(true);
    const result = await getStatistics(projectIds, year, week, weekPart);
    if (result) {
      if (result.data.result) {
        setHasSecondWeekPart(result.data?.result.hasSecondWeekPart);

        setWeekDescription(result.data?.result.weekNumber.toString());
      }

      setProjectDashboardData(result.data);
    }
    setYear(year);
    setWeek(week);
    setWeekPart(weekPart);
    setLoading(false);
  }

  async function getProjectDataForFilter() {
    const result = await getMyProjects("");

    let options = result.data.result.map(function (project) {
      return { value: project.id, label: project.name };
    });

    setProjects(options);
  }

  function toggle(newIndex) {
    if (newIndex === index) {
      setIndex("");
    } else {
      setIndex(newIndex);
    }
  }

  const handleDatePickerChange = (newDate) => {
    calculateWeekPart(newDate);

    // Now call fetchData with the new year, week, weekPart, and the existing filters
    fetchData(
      dateInfo.selectedYear,
      dateInfo.selectedWeek,
      dateInfo.weekPart,
      projectFilter
    );
  };

  const handleNextPrevDate = (isPrevious: boolean) => {
    const newData = calculateNextPrevWeek(
      isPrevious,
      week!,
      weekPart!,
      hasSecondWeekPart!,
      year!
    );

    fetchData(newData.year, newData.week, newData.weekPart, projectFilter);
  };

  const handleChangeProject = (value) => {
    setProjectFilter(value);
    const projectIds = value.map((v) => v.value);
    setProjectFilter(projectIds);

    fetchData(year!, week!, weekPart!, projectIds);
  };

  useEffect(() => {
    if (projectDashboardData) {
      const tenantAndProjectData = projectDashboardData.result.projects.map(
        (project) => ({
          title: `${project.projectName} - week ${weekDescription}`,
          id: project.projectId,
          RightNavigation: (
            <>
              <div className="me-2">
                <span className="me-4">
                  <img src={approvedLogo} />
                  <span className="ms-2">{project.numberOfApproved}</span>
                </span>
                <span className="me-4">
                  <img src={outstandingLogo} />
                  <span className="ms-2">{project.numberOfOutstanding}</span>
                </span>
                <span className="me-4">
                  <img src={declinedLogo} />
                  <span className="ms-2">{project.numberOfDeclined}</span>
                </span>
                |
                <span className="ms-3" title="Download AFAS report">
                  {downloadingFile ? (
                    <div
                      className="spinner-border spinner-border-sm"
                      role="status"
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    <>
                      <a
                        onClick={(e) => {
                          setDownloadingFile(true);
                          getProjectAfasExport(
                            project.projectId,
                            week!,
                            weekPart!,
                            year!
                          ).then((result) => {
                            setDownloadingFile(false);

                            saveAs(
                              new Blob([result.data]),
                              `Export-AFAS-${project.projectName}-${week}-${year}.xlsx`
                            );
                          });
                        }}
                        rel="noopener noreferrer"
                      >
                        <img src={downloadLogo} />
                      </a>
                    </>
                  )}
                </span>
              </div>
            </>
          ),
          body: (
            <>
              {project.items.map((day) => {
                return (
                  <AppDashboardAccordionItem
                    date={day.date}
                    description={day.description}
                    statusType={day.statusType}
                    isAdditionalRequest={false}
                  />
                );
              })}

              {project.hasAdditionalItemConfiguration && (
                <AppDashboardAccordionItem
                  date={null}
                  isAdditionalRequest={true}
                  description="Additional request"
                  statusType={project.additionalRequestStatus}
                />
              )}
            </>
          ),
        })
      );

      setAccordionData(tenantAndProjectData);
    }
  }, [week, projectDashboardData]);

  return (
    <>
      <PageTitle title="Dashboard" customStyles="mb-2" />

      <div className="d-flex justify-content-between">
        <div>
          <DateToggler
            title={
              hasSecondWeekPart || weekPart === 2 ? (
                <span className="mx-3">
                  Weekly overview - week {week}.{weekPart}
                </span>
              ) : (
                <span className="mx-3">Weekly overview - week {week}</span>
              )
            }
            onNextDate={() => handleNextPrevDate(false)}
            handleDatePickerChange={(date) => handleDatePickerChange(date)}
            onPreviousDate={() => handleNextPrevDate(true)}
          />
        </div>
        <div>
          <AppButton
            title="Export HFC period overview"
            variant="export"
            onClick={() => setShowExportModal(true)}
          />

          <AppButton
            title="Export custom range"
            variant="export"
            className="ms-2"
            onClick={() => setShowCustomRangeExportModal(true)}
          />
          <button
            type="button"
            className="btn btn-lg btn-secondary ms-2"
            onClick={() => fetchData(year, week, weekPart, projectFilter)}
          >
            <FontAwesomeIcon icon={faRedo} />
          </button>
        </div>
      </div>

      <Row className="pb-3">
        <Col lg={6} sm={6}>
          <h6>Project</h6>
          <Select isMulti options={projects} onChange={handleChangeProject} />
        </Col>
      </Row>
      {loading && (
        <Row className="text-center">
          <h4>Loading...</h4>
        </Row>
      )}

      <CustomAccordion
        key="tenant-accordion"
        open={index}
        handleOpen={toggle}
        items={accordionData}
      />

      {!loading && projectDashboardData?.result.projects.length === 0 && (
        <Row className="text-center">
          <h4>Administration has not yet been submitted...</h4>
        </Row>
      )}

      <AppModal
        show={showCommentHistoryModal}
        title="Comment history"
        handleHide={() => {
          setShowCommentHistoryModal(!showCommentHistoryModal);
          setComments([]);
        }}
      >
        <>
          {!comments && <h5>There are no comments</h5>}
          {comments?.map((comment) => (
            <MessageTimeLineItem
              userName={comment.userName}
              value={comment.value}
              action={comment.type}
              creationTime={comment.creationTime}
            />
          ))}
        </>
      </AppModal>

      <ExportHFCOverviewDialog
        handleHide={() => setShowExportModal(false)}
        showExportModal={showExportModal}
        isStaypleaseTenant={
          projectDashboardData?.result.isStaypleaseTenant || false
        }
        projects={projects}
      />

      <CustomFilterExportModal
        showExportModal={showCustomRangeExportModal}
        handleHide={() => setShowCustomRangeExportModal(false)}
      />
    </>
  );
};

export default AreaManagerDashboard;
