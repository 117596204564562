import React from "react";
import { Card, CardBody, CardHeader } from "reactstrap";
import moment from "moment";

interface MessageTimeLineItemProps {
  userName?: string;
  value: string;
  creationTime?: Date;
  action?: number
}

const MessageTimeLineItem = ({
  userName,
  value,
  creationTime,
  action
}: MessageTimeLineItemProps) => {
  return (
    <>
      <div className="d-flex mb-3">
        <Card className="w-100">
          <CardHeader>
            {userName ? (
              <>
                <strong>{userName}</strong>{" "}
                {action === 1
                  ? "approved on"
                  : action === 2
                    ? "rejected on"
                    : action === 3
                      ? "send for review on"
                      : "did an action on"} {" "}
                <strong>
                  {moment(creationTime).format("DD-MM-YYYY HH:mm")}
                </strong>
              </>
            ) : (
              <strong>Comment</strong>
            )}

          </CardHeader>
          {value && (
          <CardBody><strong>And made a comment:</strong> {value}</CardBody>
        )}
          </Card>
      </div>
    </>
  );
};

export default MessageTimeLineItem;
